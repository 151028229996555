import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap, withLatestFrom, take } from 'rxjs/operators';

import { AuctionActions, ProfileActions } from 'src/app/store/actions';
import { Store } from '@ngrx/store';

import * as fromStore from 'src/app/store/reducers'
import { LoadingController, AlertController } from '@ionic/angular';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AuctionState, auction } from '../reducers/auction.reducer';
import { Subscription, Observable } from 'rxjs';
import { firestore } from 'firebase';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable()
export class AuctionEffects {
  auctionSub: Subscription;
  itemSub: Subscription;
  bidsSub: Subscription;
  currentAuction: string = null;

  constructor(
    private actions$: Actions,
    private router: Router,
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth,
    private store: Store<fromStore.AppState>,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
  ) {
    this.store.select(fromStore.getCurrenAuction).subscribe((auction) => {
      if (this.currentAuction != auction) {
        this.currentAuction = auction;
        //console.log("[Auction.Effects] Auction State change detected");

        var authenticated = false;

        this.store.select(fromStore.isAuthenticated).pipe(take(1)).subscribe((auth) => {
          authenticated = auth;
        });

        if (this.currentAuction && this.currentAuction != '') {
          this.store.dispatch(AuctionActions.load_auction({ uid: this.currentAuction }));
          if (authenticated) {
            this.router.navigate(['/home']);
          }
        }
        else {
          this.store.dispatch(AuctionActions.clear_auction());
          if (authenticated) {
            this.router.navigate(['/auction-list']);
          }
        }

        if (!authenticated) {
          this.router.navigate(['/login']);
        }
      }

    })
  }

  public searchAuction(name: string, code: string) {
    return this.afs.collection<AuctionState>('auction', ref => {
      return ref
        .where('code', '==', code)
        .where('name', '==', name);
    });
  }

  public async submitAuctionNotFoundRequest(name: string, code: string) {
    await this.loadingCtrl.create().then((loader) => {
      // console.log('[Auth.Effects] Show loader');
      loader.present();

      // Collect the information for the support ticket

      this.afs.collection('support').add({ type: 'Auction Not Found', name: name, code: code, user_uid: this.afAuth.auth.currentUser.uid, user_email: this.afAuth.auth.currentUser.email, time: firestore.FieldValue.serverTimestamp() }).then(async () => {
        // console.log('[Auth.Effects] Hide loader');
        loader.dismiss();
        const alert = await this.alertCtrl.create({
          message: "Support request submitted. Please hold tight while we look into your issue",
          buttons: [{ text: 'Ok', role: 'cancel' }],
        })
        await alert.present();
      }).catch(async (error) => {
        // console.log('[Auth.Effects] Hide loader');
        loader.dismiss();
        const alert = await this.alertCtrl.create({
          message: "An error occured while submitting the support request. Please ensure you are connected to the internet and try again.",
          buttons: [{ text: 'Ok', role: 'cancel' }],
        })
        await alert.present();
      });
    });
  }

  public async startAuction() {
    await this.loadingCtrl.create().then((loader) => {
      // console.log('[Auth.Effects] Show loader');
      loader.present();
      const auctionRef: AngularFirestoreDocument<any> = this.afs.doc(`auction/${this.currentAuction}`);
      auctionRef.set({ state: 'In Progress' }, { merge: true }).then(() => {
        // console.log('[Auth.Effects] Hide loader');
        loader.dismiss();
      }).catch(async (error) => {
        // console.log('[Auth.Effects] Hide loader');
        loader.dismiss();
        const alert = await this.alertCtrl.create({
          message: error,
          buttons: [{ text: 'Ok', role: 'cancel' }],
        })
        await alert.present();
      });
    });
  }

  public async endAuction() {
    await this.loadingCtrl.create().then((loader) => {
      // console.log('[Auth.Effects] Show loader');
      loader.present();
      const auctionRef: AngularFirestoreDocument<any> = this.afs.doc(`auction/${this.currentAuction}`);
      auctionRef.set({ state: 'Finished' }, { merge: true }).then(() => {
        // console.log('[Auth.Effects] Hide loader');
        loader.dismiss();
      }).catch(async (error) => {
        // console.log('[Auth.Effects] Hide loader');
        loader.dismiss();
        const alert = await this.alertCtrl.create({
          message: error,
          buttons: [{ text: 'Ok', role: 'cancel' }],
        })
        await alert.present();
      });
    });
  }

  public async createItem(item) {
    await this.loadingCtrl.create().then((loader) => {
      // console.log('[Auth.Effects] Show loader');
      loader.present()
      const itemRef = this.afs.collection('items').ref.doc();
      const bidRef = this.afs.doc(`bids/${this.currentAuction}/items/${itemRef.id}`).ref;
      firestore().runTransaction(async (transaction) => {
        transaction.set(itemRef, { auction_id: this.currentAuction, ...item });
        transaction.set(bidRef, { winner: '', bidders: [] });
      }).then(function () {
        //console.log("[Auction.Effects] Item Successfully submitted");
        // console.log('[Auth.Effects] Hide loader');
        loader.dismiss()
      }).catch(async (error) => {
        // console.log("[Auction.Effects] Item Creation Failed");
        // console.log('[Auth.Effects] Hide loader');
        loader.dismiss();
        const alert = await this.alertCtrl.create({
          message: error,
          buttons: [{ text: 'Ok', role: 'cancel' }],
        })
        await alert.present();
      });
    });
  }

  public async updateItem(itemId, item) {
    await this.loadingCtrl.create().then((loader) => {
      const itemRef = this.afs.doc(`items/${itemId}`);
      itemRef.set(item, { merge: true }).then(() => {
        this.loadingCtrl.dismiss();
      }).catch(async (error) => {
        this.loadingCtrl.dismiss();
        const alert = await this.alertCtrl.create({
          message: error.message,
          buttons: [{ text: 'Ok', role: 'cancel' }],
        })
        await alert.present();
      })
    });
  }

  public async deletItem(itemId) {
    await this.loadingCtrl.create().then((loader) => {
      // console.log('[Auth.Effects] Show loader');
      loader.present()
      const itemRef = this.afs.doc(`items/${itemId}`).ref;
      const bidRef = this.afs.doc(`bids/${this.currentAuction}/items/${itemId}`).ref;
      firestore().runTransaction(async (transaction) => {
        transaction.delete(itemRef);
        transaction.delete(bidRef);
      }).then(function () {
        //console.log("[Auction.Effects] Item Successfully submitted");
        // console.log('[Auth.Effects] Hide loader');
        loader.dismiss()
      }).catch(async (error) => {
        // console.log("[Auction.Effects] Item Creation Failed");
        // console.log('[Auth.Effects] Hide loader');
        loader.dismiss();
        const alert = await this.alertCtrl.create({
          message: error,
          buttons: [{ text: 'Ok', role: 'cancel' }],
        })
        await alert.present();
      });
    });
  }

  public getBidDetails(item): Observable<any> {
    return this.afs.collection(`bids/${this.currentAuction}/items/${item.uid}/bids`, ref => {
      return ref
        .orderBy('time', "desc")
    }).valueChanges();
  }

  public async clearBids(items) {
    await this.loadingCtrl.create().then((loader) => {
      for (let item of items) {
        const itemBidRef = this.afs.doc(`bids/${this.currentAuction}/items/${item.uid}`).ref
        // TODO: Clean up sub-collections
        itemBidRef.set({ winner: '', bidders: [] }).then(() => {
        }).catch(async (error) => {
          this.loadingCtrl.dismiss();
          const alert = await this.alertCtrl.create({
            message: error.message,
            buttons: [{ text: 'Ok', role: 'cancel' }],
          })
          await alert.present();
        })
      }
      this.loadingCtrl.dismiss();
    });
  }

  public async submitBid(itemId: string, amount: number, uid: any) {
    await this.loadingCtrl.create().then((loader) => {
      // console.log('[Auth.Effects] Show loader');
      loader.present()
      this.store.select(fromStore.getUserProfile).pipe(take(1)).subscribe((user) => {
        const bidRef = this.afs.collection(`bids/${this.currentAuction}/items/${itemId}/bids`).ref.doc();
        const itemBidRef = this.afs.doc(`bids/${this.currentAuction}/items/${itemId}`).ref;
        //const itemRef = this.afs.doc(`items/${itemId}`).ref;

        return this.afs.firestore.runTransaction(async (transaction) => {
          return transaction.get(itemBidRef).then(async (itemDoc) => {
            if (!itemDoc.exists) {
              throw "Unable to find item for bid, please try again";
            }

            var current_bid = (itemDoc.data().current_bid ? itemDoc.data().current_bid : 0);
            if (amount > current_bid) {
              //transaction.update(itemRef, { current_bid: amount, winner: uid, bidders: firestore.FieldValue.arrayUnion(uid) });
              transaction.update(itemBidRef, { current_bid: amount, winner: uid, bidders: firestore.FieldValue.arrayUnion(uid) });
              transaction.set(bidRef, { item_id: itemId, amount: amount, user_id: uid, nickname: user.nickname, fullname: user.firstName + " " + user.lastName, email: this.afAuth.auth.currentUser.email, time: firestore.FieldValue.serverTimestamp() });
            }
            else {
              throw "Someone beat you to it. Please try again"
            }
          })
        }).then(function () {
          // console.log("[Auction.Effects] Bid Successfully submitted");
          // console.log('[Auth.Effects] Hide loader');
          loader.dismiss()
        }).catch(async (error) => {
          // console.log("[Auction.Effects] Bid Failed");
          // console.log('[Auth.Effects] Hide loader');
          loader.dismiss();
          const alert = await this.alertCtrl.create({
            message: error,
            buttons: [{ text: 'Ok', role: 'cancel' }],
          })
          await alert.present();
        });
      });
    });
  }

  private subscribeToAuction(uid: string) {
    if (this.auctionSub && !this.auctionSub.closed) {
      this.auctionSub.unsubscribe();
    }
    this.auctionSub = this.afs.doc<AuctionState>(`auction/${uid}`).valueChanges().subscribe((doc) => {
      this.store.dispatch(AuctionActions.set_auction({ auction: { uid: uid, ...doc } }));
    });
  }

  private subscribeToItems(auction_id: string) {
    if (this.itemSub && !this.itemSub.closed) {
      this.itemSub.unsubscribe();
    }
    this.itemSub = this.afs.collection<AuctionState>('items', ref => {
      return ref
        .where('auction_id', '==', auction_id).orderBy("name")
    }).snapshotChanges().subscribe((col) => {
      this.store.dispatch(AuctionActions.update_items({
        items: col.map((doc) => {
          return { uid: doc.payload.doc.id, ...doc.payload.doc.data() }
        })
      }));
    });
  }

  private subscribeToBids(auction_id: string) {
    if (this.bidsSub && !this.bidsSub.closed) {
      this.bidsSub.unsubscribe();
    }
    this.bidsSub = this.afs.collection(`bids/${auction_id}/items`).snapshotChanges().subscribe((col) => {
      this.store.dispatch(AuctionActions.update_bids({
        bids: col.map((doc) => {
          return { item: doc.payload.doc.id, ...doc.payload.doc.data() as {} }
        })
      }));
    });
  }

  setAuction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuctionActions.set_auction),
      tap((action) => {
        // console.log("[Auction.Effects] Auction Updated")
        this.subscribeToItems(action.auction.uid);
        this.subscribeToBids(action.auction.uid);
      })
    ), { dispatch: false }
  );

  loadAuction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuctionActions.load_auction),
      tap((action) => {
        // console.log("[Auction.Effects] Auction Loaded")
        this.subscribeToAuction(action.uid);
      })
    ), { dispatch: false }
  );

  clearAuction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuctionActions.clear_auction),
      tap(() => {
        if (this.auctionSub && !this.auctionSub.closed) {
          this.auctionSub.unsubscribe();
        }
        if (this.itemSub && !this.itemSub.closed) {
          this.itemSub.unsubscribe();
        }
      })
    ), { dispatch: false }
  );
}