import { Action, createReducer, on } from '@ngrx/store';
import * as AuthActions from '../actions/auth.actions';

export interface AuthState {
  state: string,
  uid: string,
  email: string
}

export const AUTH_STATUS = {
  UNKNOWN:         "UNKNOWN",
  UNAUTHENTICATED: "Unauthenticated",
  AUTHENTICATING:  "Authenticating",
  AUTH_FAILURE:    "Authentication Failure",
  SIGNUP:          "Signup",
  SIGNUP_FAILURE:  "Signup Failure",
  AUTHENTICATED:   "Authenticated",
}

export const initialState: AuthState = {
    state: AUTH_STATUS.UNKNOWN,
    uid: null,
    email: null
  };

export const isAuthenticated  = (auth: AuthState) => (auth.state == AUTH_STATUS.AUTHENTICATED);
export const authState        = (auth: AuthState) => (auth.state);
export const authData         = (auth: AuthState) => (auth.uid, auth.email);
export const userId           = (auth: AuthState) => (auth.uid);

const authReducer = createReducer(
initialState,
on(AuthActions.login,           state           => ({ ...state, state: AUTH_STATUS.AUTHENTICATING})),
on(AuthActions.authenticated,   (state, action) => ({ ...state, state: AUTH_STATUS.AUTHENTICATED, uid: action.uid, email: action.email})),
on(AuthActions.invalidate,      state           => ({ ...state, state: AUTH_STATUS.UNAUTHENTICATED, uid: null, email: null })),
on(AuthActions.login_failed,    state           => ({ ...state, state: AUTH_STATUS.AUTH_FAILURE })),
on(AuthActions.login_success,   state           => ({ ...state })),
on(AuthActions.signup,          state           => ({ ...state, state: AUTH_STATUS.SIGNUP })),
on(AuthActions.signup_failed,   state           => ({ ...state, state: AUTH_STATUS.SIGNUP_FAILURE  })),
on(AuthActions.signup_success,  state           => ({ ...state })),
on(AuthActions.forgot_password, state           => ({ ...state })),
);
  
export function reducer(auth: AuthState | undefined, action: Action) {
  return authReducer(auth, action);
}