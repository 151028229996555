import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../../environments/environment';

import * as fromAuth from "./auth.reducer";
import * as fromProfile from "./profile.reducer";
import * as fromAuction from "./auction.reducer";
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { Params } from '@angular/router';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

export interface AppState {
  auth: fromAuth.AuthState,
  profile: fromProfile.ProfileState,
  router: RouterReducerState<RouterStateUrl>,
  auction: fromAuction.AuctionState
}

export const reducers: ActionReducerMap<AppState> = {
  auth: fromAuth.reducer,
  profile: fromProfile.reducer,
  router: routerReducer,
  auction: fromAuction.reducer
};

export const selectReducerState = createFeatureSelector<RouterReducerState<RouterStateUrl>>("router");
export const selectAuthState    = createFeatureSelector<fromAuth.AuthState>("auth");
export const selectProfileState = createFeatureSelector<fromProfile.ProfileState>("profile");
export const selectAuctionState = createFeatureSelector<fromAuction.AuctionState>("auction");

// Router Selectors
export const getRouterInfo = createSelector(selectReducerState, state => state.state);

// Auth Selectors
export const getAuthData     = createSelector(selectAuthState, fromAuth.authData);
export const isAuthenticated = createSelector(selectAuthState, fromAuth.isAuthenticated);
export const getUserId       = createSelector(selectAuthState, fromAuth.userId);

// Profile Selectors
export const getUserProfile   = createSelector(selectProfileState, fromProfile.userProfile);
export const getCurrenAuction = createSelector(selectProfileState, fromProfile.currentAuction);

// Auction Selectors
export const getAuction        = createSelector(selectAuctionState, fromAuction.auction);
export const getAuctionItems   = createSelector(selectAuctionState, fromAuction.auctionItems);
export const getAuctionDetails = createSelector(selectAuctionState, fromAuction.auctionDetails);
export const getItem           = createSelector(selectAuctionState, fromAuction.auctionItem);
export const getBidItems       = createSelector(selectAuctionState, fromAuction.bidItems);
export const getWinningItems   = createSelector(selectAuctionState, fromAuction.winningItems);
export const getItemBids       = createSelector(selectAuctionState, fromAuction.itemBids);
export const getItemBid        = createSelector(selectAuctionState, fromAuction.itemBid);
export const getAuctionState   = createSelector(selectAuctionState, fromAuction.auctionState);
export const isAdministrator   = createSelector(selectAuctionState, fromAuction.administrator);
export const totalBids         = createSelector(selectAuctionState, fromAuction.total);


export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
