import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap, switchMap } from 'rxjs/operators';

import { AuthActions } from 'src/app/store/actions';
import { AngularFireAuth } from '@angular/fire/auth';
import { Store } from '@ngrx/store';

import * as fromStore from 'src/app/store/reducers'
import { authenticated, invalidate } from 'src/app/store/actions/auth.actions';
import { LoadingController, AlertController } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';



@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public afs: AngularFirestore,   // Inject Firestore service
    private store: Store<fromStore.AppState>,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
  ) {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        // console.log("[Auth.Effects] User State Authenticated");
        this.store.dispatch(authenticated({ uid: user.uid, email: user.email }));
      }
      else {
        // console.log("[Auth.Effects] User State Invalid");
        this.store.dispatch(invalidate());
      }
    })
  }

  invalidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.invalidate),
      tap(() => {
        this.router.navigate(['/login']);
      }),
    ), { dispatch: false }
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      tap(async () => {
        // console.log('[Auth.Effects] Show loader');
        await this.loadingCtrl.create().then((loader) => loader.present());
      }),
      switchMap((action) => {
        return this.afAuth.auth.signInWithEmailAndPassword(action.email, action.password).then((result) => {
          // console.log('[Auth.Effects] Sign In Success for user ' + action.email);
          // console.log('[Auth.Effects] Hide loader');
          this.loadingCtrl.dismiss();
          return AuthActions.login_success();
        }).catch(async (error) => {
          // console.log("[Auth.Effects] Sign In Error");
          // console.log('[Auth.Effects] Hide loader');
          this.loadingCtrl.dismiss();
          const alert = await this.alertCtrl.create({
            message: "Invalid Username or Password. Please check and try again",
            buttons: [{ text: 'Ok', role: 'cancel' }],
          });
          await alert.present();
          return AuthActions.login_failed();
        })
      })
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      tap(() => {
        this.afAuth.auth.signOut().then((result) => {
          // console.log('[Auth.Effects] Sign Out Success');
        }).catch((error) => {
          // console.log("[Auth.Effects] Sign Out Error");
        })
      }),
    ), { dispatch: false }
  );

  signup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signup),
      tap(async () => {
        // console.log('[Auth.Effects] Show loader');
        await this.loadingCtrl.create().then((loader) => loader.present());
      }),
      switchMap((action) => {
        return this.afAuth.auth.createUserWithEmailAndPassword(action.email, action.password)
          .then(async (newUserCredential: firebase.auth.UserCredential) => {
            let user = action.user;
            this.afs
              .firestore
              .doc(`/users/${newUserCredential.user.uid}`)
              .set({ firstName: user.firstName, lastName: user.lastName, nickname: user.nickname })
              .then(async () => {
                // console.log("[Auth.Effects] Sign Up Complete");
                // console.log('[Auth.Effects] Hide loader');
                this.loadingCtrl.dismiss();
                return AuthActions.signup_success();
              })
              .catch(async (error) => {
                this.afAuth.auth.currentUser.delete();
                // console.log(error);
                this.loadingCtrl.dismiss();
                const alert = await this.alertCtrl.create({
                  message: "An error occured while attempting to Sign Up. Please Try again later",
                  buttons: [{ text: 'Ok', role: 'cancel' }],
                });
                await alert.present();
                return AuthActions.signup_failed()
              });
              return AuthActions.signup_failed()
          })
          .catch(async (error) => {
            // console.log("[Auth.Effects] Sign Up Error");
            // console.log('[Auth.Effects] Hide loader');
            // console.log(error);
            this.loadingCtrl.dismiss();
            const alert = await this.alertCtrl.create({
              message: error.message,
              buttons: [{ text: 'Ok', role: 'cancel' }],
            });
            await alert.present();
            return AuthActions.signup_failed()
          });
      })
    )
  );

  forgotpassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.forgot_password),
      tap(async () => {
        // console.log('[Auth.Effects] Show loader');
        await this.loadingCtrl.create().then((loader) => loader.present());
      }),
      switchMap(async (action) => {
        return this.afAuth.auth.sendPasswordResetEmail(action.email)
          .then(async (result) => {
            // console.log("[Auth.Effects] Password Reset Email Success");
            // console.log('[Auth.Effects] Hide loader');
            this.loadingCtrl.dismiss();
            const alert = await this.alertCtrl.create({
              message: "A password reset email has been sent. Please follow the instructions within this email to reset your password",
              buttons: [{ text: 'Ok', role: 'cancel' }],
            });
            await alert.present();
            return AuthActions.forgot_password_success();
          })
          .catch(async (error) => {
            // console.log("[Auth.Effects] Password Reset Email Error");
            // console.log('[Auth.Effects] Hide loader');
            this.loadingCtrl.dismiss();
            const alert = await this.alertCtrl.create({
              message: error.message,
              buttons: [{ text: 'Ok', role: 'cancel' }],
            });
            await alert.present();
            return AuthActions.forgot_password_failed()
          });
      })
    )
  );

  forgotpassword_success$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.forgot_password_success),
      tap(() => {
        this.router.navigate(['/login']);
      }),
    ), { dispatch: false }
  );
}