import { createAction, props } from '@ngrx/store';
import { ProfileState } from '../reducers/profile.reducer';

export const authenticated   = createAction('[Auth] Authenticated', props<{ uid: string, email: string }>());
export const login           = createAction('[Auth] Login', props<{ email: string, password: string }>())
export const login_failed    = createAction('[Auth] Login Failed');
export const login_success   = createAction('[Auth] Login Success');
export const logout          = createAction('[Auth] Logout')
export const invalidate      = createAction('[Auth] Invalidate');
export const signup          = createAction('[Auth] SignUp', props<{ email: string, password: string, user: ProfileState }>())
export const signup_failed   = createAction('[Auth] SignUp Failed');
export const signup_success  = createAction('[Auth] SignUp Success');
export const forgot_password = createAction('[Auth] Forgot Password', props<{ email: string }>());
export const forgot_password_success = createAction('[Auth] Forgot Password Sucess');
export const forgot_password_failed  = createAction('[Auth] Forgot Password Failed');