import { Action, createReducer, on } from '@ngrx/store';
import * as AuctionActions from '../actions/auction.actions';
import { AngularFirestoreDocument } from '@angular/fire/firestore';
import { firestore } from 'firebase';


export interface AuctionState {
  uid: string;
  name: string;
  description: string;
  start_time: firestore.Timestamp;
  end_time: firestore.Timestamp;
  logo: string;
  items: [ any ];
  bids: [ any ];
  administrators: [ string ];
  state: string;
  win_message: string;
  lose_message: string;
}

export const AUCTION_STATUS = {
  UNKNOWN:    "UNKNOWN",
  NOTSTARTED: "Not Started",
  INPROGRESS: "In Progress",
  FINISHED:   "Finished"
}

export const initialState: AuctionState = {
  uid: null,
  name: null,
  description: null,
  start_time: null,
  end_time: null,
  logo: null,
  items: null,
  bids: null,
  administrators: null,
  state: AUCTION_STATUS.UNKNOWN,
  win_message: null,
  lose_message: null
  };

export const auction        = (auction: AuctionState) => (auction);
export const auctionItems   = (auction: AuctionState) => (auction.items);
export const itemBids       = (auction: AuctionState) => (auction.bids);
export const auctionDetails = (auction: AuctionState) => ({ name: auction.name, description: auction.description, logo: auction.logo, start_time: auction.start_time, end_time: auction.end_time, state: auction.state, win_message: auction.win_message,  lose_message: auction.lose_message});
export const auctionItem    = (auction: AuctionState, props: any) => (auction.items ? auction.items.find(i => i.uid === props.itemId) : null);
export const itemBid        = (auction: AuctionState, props: any) => (auction.bids ? auction.bids.find(i => i.item === props.itemId) : null);
export const administrator  = (auction: AuctionState, props: any) => (auction.administrators && auction.administrators.find(i => i == props.userId) != undefined);
export const bidItems       = (auction: AuctionState, props: any) => (auction.items ? auction.items.filter(i => i != null && auction.bids && auction.bids.find((bid) => bid.item == i.uid && bid.bidders.find(j => j == props.uid))): null);
export const winningItems   = (auction: AuctionState, props: any) => (auction.items ? auction.items.filter(i => i != null && auction.bids && auction.bids.find((bid) => bid.item == i.uid && bid.winner == props.uid)): null);
export const auctionState   = (auction: AuctionState) => (auction.state);
export const total          = (auction: AuctionState) => (auction.bids ? auction.bids.reduce((a, b) => +a + +(b.current_bid ? b.current_bid : 0), 0) : 0);

const auctionReducer = createReducer(
initialState,
on(AuctionActions.set_auction,   (state, action) => ( action.auction )),
on(AuctionActions.load_auction,  (state, action) => ( { ...state } )),
on(AuctionActions.clear_auction, state           => ( initialState )),
on(AuctionActions.update_items,  (state, action) => ( { ...state, items: action.items } )),
on(AuctionActions.update_bids,   (state, action) => ( { ...state, bids: action.bids } )),
on(AuctionActions.set_state,     (state, action) => ( { ...state, state: action.state }))
);
  
export function reducer(auction: AuctionState | undefined, action: Action) {
  return auctionReducer(auction, action);
}