import { Action, createReducer, on } from '@ngrx/store';
import * as ProfileActions from '../actions/profile.actions';

export interface ProfileState {
  firstName: string;
  lastName: string;
  nickname: string;
  current_auction?: string;
}

export const initialState: ProfileState = {
    firstName: null,
    lastName: null,
    nickname: null
  };

export const userProfile  = (profile: ProfileState) => (profile);
export const currentAuction = (profile: ProfileState) => (profile.current_auction);

const profileReducer = createReducer(
initialState,
on(ProfileActions.set_profile,    (state, action) => ( action.profile )),
on(ProfileActions.update_profile, (state, action) => ({ ...state })),
on(ProfileActions.set_current_auction, (state, action) => ({ ...state })),
on(ProfileActions.clear_profile,   state          => ( initialState )),
);
  
export function reducer(profile: ProfileState | undefined, action: Action) {
  return profileReducer(profile, action);
}