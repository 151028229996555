import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'welcome', pathMatch: 'full' },
  { path: 'welcome',          loadChildren: () => import('src/app/pages/welcome/welcome.module').then( m => m.WelcomePageModule)},
  { path: 'home',             loadChildren: () => import('src/app/home/home.module').then( m => m.HomePageModule), canActivateChild: [AuthGuard]},
  { path: 'login',            loadChildren: () => import('src/app/pages/auth/login/login.module').then( m => m.LoginPageModule)},
  { path: 'signup',           loadChildren: () => import('src/app/pages/auth/signup/signup.module').then( m => m.SignupPageModule)},
  { path: 'forgot-password',  loadChildren: () => import('src/app/pages/auth/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)},
  { path: 'auction-list',     loadChildren: () => import('src/app/pages/auction/auction-list/auction-list.module').then( m => m.AuctionListPageModule), canActivateChild: [AuthGuard]}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
