import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap, withLatestFrom, take } from 'rxjs/operators';

import { AuthActions, ProfileActions, AuctionActions } from 'src/app/store/actions';
import { Store } from '@ngrx/store';

import * as fromStore from 'src/app/store/reducers'
import { LoadingController, AlertController } from '@ionic/angular';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import { ProfileState } from '../reducers/profile.reducer';

import { firestore } from 'firebase';



@Injectable()
export class ProfileEffects {
  profileSub: Subscription;

  constructor(
    private actions$: Actions,
    private router: Router,
    public afs: AngularFirestore,   // Inject Firestore service
    private store: Store<fromStore.AppState>,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
  ) { }

  private subscriptToProfileChanges(uid: string) {
    this.profileSub = this.afs.doc<ProfileState>(`users/${uid}`).valueChanges().subscribe((doc) => {
      this.store.dispatch(ProfileActions.set_profile({ profile: doc }));
      // this.store.select(fromStore.getCurrenAuction).pipe(take(1)).subscribe((auction) => {
      // this.store.dispatch(ProfileActions.set_profile({ profile: doc }));
      //   if(auction != doc.current_auction)
      //     console.log("[Profile.Effects] Auction State change detected");
      //     if(doc.current_auction && doc.current_auction != '') {
      //       this.store.dispatch(AuctionActions.load_auction({ uid: doc.current_auction }));
      //     } else {
      //       this.store.dispatch(AuctionActions.clear_auction);
      //     }
      // });
    });
  }

  //Effects
  authenticated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authenticated),
      tap((action) => {
        this.subscriptToProfileChanges(action.uid);
        this.afs.doc<ProfileState>(`users/${action.uid}`).ref.get().then((doc) => {
          if(doc.data().current_auction && doc.data().current_auction != '') {
            this.router.navigate(['/home']);
          } else {
            this.router.navigate(['/auction-list']);
          }
          
        });
        this.loadingCtrl.dismiss().catch(() => {});
      })
    ), { dispatch: false }
  );

  invalidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.invalidate),
      tap(() => {
        if(this.profileSub && !this.profileSub.closed)
          this.profileSub.unsubscribe();
      }),
      map(() => {
        // console.log('[Profile.Effects] Auth Invalidation Detected');
        return ProfileActions.clear_profile();
      })
    )
  );

  setAuction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.set_current_auction),
      withLatestFrom(this.store),
      tap(([action, store]) => {
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${store.auth.uid}`);
        if(action.uid) {
          userRef.set({ current_auction: action.uid, previous_auctions: firestore.FieldValue.arrayUnion(action.uid) }, {merge: true});
        } else {
          userRef.set({ current_auction: action.uid }, {merge: true});
        }
      }),
    ), { dispatch: false }
  );

  updateProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.update_profile),
      tap(async () => {
        // console.log('[Auth.Effects] Show loader');
        await this.loadingCtrl.create().then((loader) => loader.present());
      }),
      withLatestFrom(this.store),
      tap(([action, store]) => {
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${store.auth.uid}`);
        userRef.set(action.profile, { merge: true }).then(() => {
          // console.log('[Auth.Effects] Hide loader');
          this.loadingCtrl.dismiss();
        }).catch(async (error) => {
          // console.log("[Auth.Effects] Sign Up Error");
          // console.log('[Auth.Effects] Hide loader');
          this.loadingCtrl.dismiss();
          const alert = await this.alertCtrl.create({
            message: error.message,
            buttons: [{ text: 'Ok', role: 'cancel' }],
          })
          await alert.present();
        })
      }),
    ), { dispatch: false }
  );
}