import { Injectable, NgZone } from '@angular/core';
import { Router, CanActivate, CanActivateChild } from '@angular/router';
import { Store } from '@ngrx/store';

import * as fromAuth from 'src/app/store/reducers/auth.reducer'
import { isAuthenticated } from 'src/app/store/reducers/auth.reducer';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivateChild {
  constructor(
    private router: Router, 
    private store: Store<fromAuth.AuthState>,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {}

  canActivateChild() {
    if(this.store.select<boolean>(isAuthenticated)) {
      return true;
    } else {
      this.ngZone.run(() => {
        this.router.navigate(['/login']);
      })
      return false;
    }
  }
}